//====================================================
//  Function: Screen resolution add class
//====================================================
function addActiveClassScreenResolution() {
  if ($('.js-tab-content-item-first').length) {
    if ($(window).width() > bpSmall) {
      $('.js-tabs-content  .js-tab-content-item-first').addClass(classIsActive)
    } else {
      // 	$(
      // 		'.js-tabs-content .js-tabs-content .js-tab-content-item-first',
      // 	).addClass(classIsActive)
    }
  }
}
