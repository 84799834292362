//====================================================
//  Initialization scroll
//====================================================
function componentSideNavigation() {
  if ($('#scroll-menu').length) {
    checkScroll()
  }
}

if ($('#scroll-menu').length) {
  var scrolling = false
  var header = $('.js-navigation')
  var contentSections = $('.section-scroll'),
    verticalNavigation = $('#scroll-menu'),
    navigationItems = verticalNavigation.find('a')
}

function checkScroll() {
  if (!scrolling) {
    scrolling = true
    !window.requestAnimationFrame
      ? setTimeout(updateSections, 300)
      : window.requestAnimationFrame(updateSections)
  }
}

function updateSections() {
  windowScrollTop = $(window).scrollTop()

  contentSections.each(function () {
    var section = $(this),
      height = header.height() + 5,
      sectionId = section.attr('id')
    var navigationItem = navigationItems.filter('[href^="#' + sectionId + '"]')

    if (section.position().top - height - 150 < windowScrollTop) {
      section.find('.headline-line').addClass(classIsActive)
    }

    // if (
    //   section.position().top - height < windowScrollTop &&
    //   section.position().top + section.innerHeight() - height > windowScrollTop
    // ) {
    //   navigationItem.closest('li').addClass(classIsActive)
    // } else {
    //   navigationItem.closest('li').removeClass(classIsActive)
    // }
  })
  scrolling = false
}
