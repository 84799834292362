/* ==========================================================================
 *  Function: Dropdown
 * ======================================================================= */
function partDropdown() {
  if ($('.js-dropdown-trigger').length) {
    $('.js-dropdown-trigger').on('click', function () {
      if ($(window).width() < bpMedium) {
        $(this).closest('.js-dropdown-block').toggleClass(classIsActive)
        $(this).closest('.main-nav').toggleClass('show-one-item')
      }
    })
  }
}
