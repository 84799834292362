//====================================================
//  Function: Tabs
//====================================================
function componentTabs() {
  if ($('.js-tabs-group').length) {
    $('.js-tabs-group .js-tab-list-item').click(function () {
      var inst = $(this)
      var thisId = inst.attr('data-id')
      var thisTabs = $(this).closest('.js-tabs-group')

      if (thisId == 'undefined') {
        return false
      }

      if (!inst.hasClass(classIsActive)) {
        thisTabs
          .find('.js-tab-list-item.' + classIsActive + '')
          .removeClass(classIsActive)
        $(this).closest('.js-tab-list-item').addClass(classIsActive)
        thisTabs
          .find('.js-tabs-content .js-tab-content-item.' + classIsActive + '')
          .removeClass(classIsActive)
        thisTabs
          .find(
            '.js-tabs-content .js-tab-content-item[data-id="' + thisId + '"]',
          )
          .addClass(classIsActive)
      }
    })

    $(document).on(
      'click',
      '.js-tabs-group .js-tab-list-item-sub',
      function () {
        var inst = $(this)
        var thisId = inst.attr('data-id')
        var thisTabs = $(this).closest('.js-tabs-group')

        if (thisId == 'undefined') {
          return false
        }

        if (!inst.hasClass(classIsActive)) {
          inst
            .closest('.js-tabs-list')
            .find('.js-tab-list-item-sub.' + classIsActive + '')
            .removeClass(classIsActive)
          $(this).closest('.js-tab-list-item-sub').addClass(classIsActive)
          thisTabs
            .find(
              '.js-tabs-content .js-tab-content-item[data-id="' + thisId + '"]',
            )
            .closest('.js-tabs-content')
            .find('.js-tab-content-item.' + classIsActive + '')
            .removeClass(classIsActive)
          thisTabs
            .find(
              '.js-tabs-content .js-tab-content-item[data-id="' + thisId + '"]',
            )
            .addClass(classIsActive)
            .closest('.js-tab-content-item')
            .addClass(classIsActive)

          if (inst.hasClass('scroll-item')) {
            $('html, body')
              .stop()
              .animate({
                scrollTop:
                  thisTabs
                    .find(
                      '.js-tabs-content .js-tab-content-item[data-id="' +
                        thisId +
                        '"]',
                    )
                    .offset().top -
                  $('.js-navigation').height() -
                  50,
              })
          }
          if ($(window).width() > bpSmall) {
            thisTabs
              .find(
                '.js-tabs-content .js-tab-content-item[data-id="' +
                  thisId +
                  '"]',
              )
              .closest('.js-tab-content-item')
              .find('.js-tabs-content .js-tab-content-item:first-child')
              .addClass(classIsActive)
          }
        }
      },
    )

    $(document).on(
      'click',
      '.js-tabs-list.tabs-list--main .js-tab-list-item-sub',
      function () {
        var inst = $(this)

        if ($(window).width() > bpSmall) {
          $(
            '.js-tabs-list.tabs-list--submenu .js-tab-list-item-sub',
          ).removeClass(classIsActive)
          $(
            '.js-tabs-list.tabs-list--submenu .js-tab-list-item-sub:first-child',
          ).addClass(classIsActive)
        }
      },
    )

    $('.js-tabs-group .js-tab-list-item-hover').hover(function () {
      var inst = $(this)
      var thisId = inst.attr('data-id')
      var thisTabs = $(this).closest('.js-tabs-group')

      if (thisId == 'undefined') {
        return false
      }

      if (!inst.hasClass(classIsActive)) {
        thisTabs
          .find('.js-tab-list-item-hover.' + classIsActive + '')
          .removeClass(classIsActive)
        $(this).closest('.js-tab-list-item-hover').addClass(classIsActive)
        thisTabs
          .find('.js-tabs-content .js-tab-content-item.' + classIsActive + '')
          .removeClass(classIsActive)
        thisTabs
          .find(
            '.js-tabs-content .js-tab-content-item[data-id="' + thisId + '"]',
          )
          .addClass(classIsActive)
      }
      return false
    })
  }
}
