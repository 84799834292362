//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  heroSlider()
  referenceSlider()
  productsSlider()
  classicSlider()
  gallerySlider()
  // realizationSlider()
  // aboutSlider()
  // classicSlider()
  // galleryMainSlider()
  // galleryRealizationSlider()
  // galleryThumbnailSlider()
}

// var galleryThumbnails

// function galleryThumbnailSlider() {
//   if ($('.js-slider-gallery-thumbnails').length) {
//     var inst = $('.js-slider-gallery-thumbnails'),
//       swiperBlock = inst.closest('.js-slider-group'),
//       swiperArrowPrev = $('.js-swiper-arrow-prev-thumbnail'),
//       swiperArrowNext = $('.js-swiper-arrow-next-thumbnail')

//     galleryThumbnails = new Swiper(inst, {
//       lazy: true,
//       loop: false,
//       spaceBetween: 10,
//       slideToClickedSlide: true,

//       // Navigation arrows
//       navigation: {
//         nextEl: swiperArrowNext,
//         prevEl: swiperArrowPrev,
//       },

//       breakpoints: {
//         200: {
//           slidesPerView: 1,
//         },
//         576: {
//           slidesPerView: 2,
//         },
//         768: {
//           slidesPerView: 2,
//         },
//         992: {
//           slidesPerView: 3,
//         },
//         1200: {
//           slidesPerView: 3,
//         },
//       },
//     })
//   }
// }

var productsSwiper

function productsSlider() {
  if ($('.js-slider-products').length) {
    var inst = $('.js-slider-products .swiper-container'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    productsSwiper = new Swiper(inst, {
      spaceBetween: 30,
      // lazy: true,
      loop: true,
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: true,
      // },
      speed: 600,
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
      breakpoints: {
        200: {
          spaceBetween: 10,
          slidesPerView: 2,
        },
        576: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      },
    })
  }
}

var productsSwiper

function classicSlider() {
  if ($('.js-slider-classic').length) {
    var inst = $('.js-slider-classic .swiper-container'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    classicSwiper = new Swiper(inst, {
      spaceBetween: 30,
      // lazy: true,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      speed: 600,
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
      breakpoints: {
        200: {
          spaceBetween: 10,
          slidesPerView: 1,
        },
        576: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      },
    })
  }
}

var heroSliderInst

function heroSlider() {
  if ($('.js-slider-hero').length) {
    var inst = $('.js-slider-hero'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination')

    heroSliderInst = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 600,
      effect: 'fade',
      fadeEffect: {
        crossFade: false,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      // navigation: {
      //   nextEl: swiperArrowNext,
      //   prevEl: swiperArrowPrev,
      // },
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
    })

    heroSliderInst.on('slideChange', function () {
      var heroIndex = heroSliderInst.realIndex
      $('.js-hero-headline-group').removeClass(classIsActive)
      $(
        '.js-hero-headline-group[data-swiper-slide-index="' + heroIndex + '"]',
      ).addClass(classIsActive)

      $('.hero-tab .js-hero-tab-content').removeClass(classIsActive)
      $(
        '.hero-tab .js-hero-tab-content[data-id="hero-slide-' +
          heroIndex +
          '"]',
      ).addClass(classIsActive)
    })
  }
}

var referenceSliderInst

function referenceSlider() {
  if ($('.js-slider-reference').length) {
    var inst = $('.js-slider-reference'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    referenceSliderInst = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 600,
      effect: 'fade',
      fadeEffect: {
        crossFade: false,
      },
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: true,
      // },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
    })

    referenceSliderInst.on('slideChange', function () {
      var heroIndex = referenceSliderInst.realIndex
      $('.js-hero-headline-group').removeClass(classIsActive)
      $(
        '.js-hero-headline-group[data-swiper-slide-index="' + heroIndex + '"]',
      ).addClass(classIsActive)

      $('.hero-tab .js-hero-tab-content').removeClass(classIsActive)
      $(
        '.hero-tab .js-hero-tab-content[data-id="hero-slide-' +
          heroIndex +
          '"]',
      ).addClass(classIsActive)
    })
  }
}

// var galleryClassic

// function classicSlider() {
//   if ($('.js-slider-classic').length) {
//     var inst = $('.js-slider-classic'),
//       swiperBlock = inst.closest('.js-slider-group'),
//       swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
//       swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

//     galleryClassic = new Swiper(inst, {
//       slidesPerView: 1,
//       spaceBetween: 0,
//       lazy: true,
//       loop: true,
//       speed: 600,
//       navigation: {
//         nextEl: swiperArrowNext,
//         prevEl: swiperArrowPrev,
//       },
//     })

//     galleryClassic.on('slideChangeTransitionEnd', function () {
//       var currentType = $('.js-slider-classic .swiper-slide-active').data(
//         'type',
//       )
//       $('.js-gallery-classic-items').removeClass(classIsActive)
//       $('.js-gallery-classic-items[data-type="' + currentType + '"]').addClass(
//         classIsActive,
//       )
//     })
//   }
// }

// function galleryMainSlider() {
//   if ($('.js-slider-gallery-main').length) {
//     var inst = $('.js-slider-gallery-main'),
//       swiperBlock = inst.closest('.js-slider-group'),
//       swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
//       swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

//     var mySwiper = new Swiper(inst, {
//       slidesPerView: 1,
//       spaceBetween: 0,
//       lazy: true,
//       loop: true,
//       speed: 600,
//       navigation: {
//         nextEl: swiperArrowNext,
//         prevEl: swiperArrowPrev,
//       },
//     })
//   }
// }

// var galleryRealization

// function galleryRealizationSlider() {
//   if ($('.js-slider-gallery-realization').length) {
//     var inst = $('.js-slider-gallery-realization'),
//       swiperBlock = inst.closest('.js-slider-group'),
//       swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
//       swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

//     galleryRealization = new Swiper(inst, {
//       slidesPerView: 1,
//       spaceBetween: 0,
//       lazy: true,
//       loop: true,
//       speed: 600,
//       navigation: {
//         nextEl: swiperArrowNext,
//         prevEl: swiperArrowPrev,
//       },
//     })
//   }
// }

// var galleryThumbnails

// function galleryThumbnailSlider() {
//   if ($('.js-slider-gallery-thumbnails').length) {
//     var inst = $('.js-slider-gallery-thumbnails'),
//       swiperBlock = inst.closest('.js-slider-group'),
//       swiperArrowPrev = $('.js-swiper-arrow-prev-thumbnail'),
//       swiperArrowNext = $('.js-swiper-arrow-next-thumbnail')

//     galleryThumbnails = new Swiper(inst, {
//       lazy: true,
//       loop: true,
//       spaceBetween: 10,
//       slideToClickedSlide: true,

//       // Navigation arrows
//       navigation: {
//         nextEl: swiperArrowNext,
//         prevEl: swiperArrowPrev,
//       },

//       breakpoints: {
//         200: {
//           slidesPerView: 2,
//         },
//         576: {
//           slidesPerView: 2,
//         },
//         768: {
//           slidesPerView: 3,
//         },
//         992: {
//           slidesPerView: 3,
//         },
//         1200: {
//           slidesPerView: 4,
//         },
//       },
//     })

//     galleryThumbnails.on('slideChangeTransitionEnd', function () {
//       var currentSlide = galleryThumbnails.realIndex
//       var currentType = $(
//         '.js-slider-gallery-thumbnails .swiper-slide-active',
//       ).data('type')
//       $('.js-gallery-thumbitems').removeClass(classIsActive)
//       $('.js-gallery-thumbitems[data-type="' + currentType + '"]').addClass(
//         classIsActive,
//       )

//       $('#form_enquire_material')
//         .closest('.js-placeholder-block')
//         .addClass(classIsActive)
//       $('#form_enquire_material').val(currentType)
//       $('#form_enquire_material').trigger('change')
//     })
//   }
// }

var swiperGallery

function gallerySlider() {
  if ($('.js-slider-gallery').length) {
    var inst = $('.js-slider-gallery'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    if ($(window).width() < bpMedium) {
      var autoplay = {
        delay: 4000,
        disableOnInteraction: true,
      }
    } else {
      var autoplay = ''
    }

    swiperGallery = new Swiper(inst, {
      effect: 'coverflow',
      slidesPerView: 2,
      paginationClickable: true,
      spaceBetween: 0,
      loop: true,
      centeredSlides: true,
      variableWidth: false,
      speed: 500,
      observer: true,
      observeParents: true,
      resizeObserver: true,
      autoplay: autoplay,
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 70,
        modifier: 3,
        slideShadows: false,
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        992: {
          slidesPerView: 'auto',
          spaceBetween: 200,
        },
        1220: {
          slidesPerView: 'auto',
          spaceBetween: 200,
        },
        1440: {
          slidesPerView: 'auto',
          spaceBetween: 200,
        },
      },
    })
  }
}
