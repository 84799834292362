// --- GLOBAL VARIABLES --- //
var $body = $('body')
var $window = $(window)
var $document = $(document)
var $header = $('.header')
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var $this = null
var $containers = $('html,body')

// --- COMPONENTS, PARTS --- //
$(document).ready(function () {
  // ---  COMPONENTS --- //

  // tabs
  componentTabs()

  // Modals
  componentModal()

  // Accordion
  componentAccordion()

  // Google Maps
  // componentGoogleMaps()

  // Navigation
  componentFullscreenNavigation()

  // Validate forms
  componentValidateForms()

  // Ajax
  componentAjaxLoad()

  // Textarea Resize
  componentTextareaResize()

  // Scroll to
  partScrollTo()

  // Placeholder
  partPlaceholder()

  // Show Hidden
  partShowHidden()

  // Side navigation
  componentSideNavigation()

  componentProductList()

  // Scroll Resize
  partScrollResize()

  partDropdown()
})

var resizeTimer

// --- SCROLL EVENT --- //
$(document).scroll(function () {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
    componentSideNavigation()
    componentProductList()
  })
})

// --- RESIZE EVENT --- //
$(window).resize(function () {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
  })
})

// --- LOAD EVENT --- //
$(window).bind('load', function () {
  // Page Loader
  $('body').addClass(classIsActive)

  // Swiper
  componentSwiper()

  addActiveClassScreenResolution()

  $('.js-hero-headline-group[data-swiper-slide-index="0"]').addClass(
    classIsActive,
  )

  if ($('.submenu-top-item-first').length) {
    if ($(window).width() > bpMedium) {
      $('.submenu-top-item-first').addClass(classIsActive)
    } else {
      $('.submenu-top-item-first').removeClass(classIsActive)
    }
  }
})
