//====================================================
//  Function: Scroll & resize events
//====================================================
var windowScrollTop
var windowWidth
// var bottomTop;
// var bottomHeight;

if ($('.js-navigation').length) {
  var navigation = $('.js-navigation')
}

if ($('#section-plaster').length) {
  var heroSection = $('#section-plaster')
  var sectionHeight
  var bodyHeight
}

if ($('#detail-menu-bar').length) {
  var detailBar = $('#detail-menu-bar')
  var detailSectionHeadline = $('#section-detail-headline')
  var detailHeight
}

var onceInit = false

function partScrollResize() {
  windowScrollTop = $(window).scrollTop()
  windowWidth = $(window).width()

  if ($('.js-navigation').length) {
    if (windowScrollTop > 1 && windowWidth > bpMedium) {
      navigation.addClass('is-scrolling')
      fn_secondary_header_auto_hide(windowScrollTop)
    } else {
      navigation.removeClass('is-scrolling')
      if ($('#detail-menu-bar').length) {
        return false
      }
    }
  }

  if ($('#section-plaster').length && windowWidth > bpMedium && onceInit) {
    // var bottomTop = $('.content-offset-top').offset().top
    sectionHeight = $('.hero-section--index').outerHeight()
    bodyHeight = $('body').outerHeight()

    if (windowScrollTop > sectionHeight) {
      // $body.addClass('page-stuck')
      onceInit = false
      // componentIndexScroll()
    } else {
      $body.removeClass('page-stuck')
    }
  }

  if ($('#detail-menu-bar').length) {
    detailHeight = parseInt(detailSectionHeadline.innerHeight())
    additionalHeight = 0

    if (windowWidth < bpMedium) {
      detailHeight = parseInt(detailSectionHeadline.innerHeight())
      additionalHeight = 0
    } else {
      additionalHeight = -80
    }

    if (windowScrollTop >= detailHeight + additionalHeight) {
      detailBar.addClass('detail-tab-block-scroll')
      navigation.addClass('navigation-scroll-bottom')
      navigation.addClass('is-scrolling')
      fn_secondary_navigation_auto_hide(detailBar, windowScrollTop)
    } else {
      $body.removeClass('hide-header-bottom')
      navigation.removeClass('navigation-scroll-bottom')
      // navigation.removeClass('is-scrolling')
      detailBar.removeClass('detail-tab-block-scroll')
      detailBar.removeClass('hide-navigation')
      $body.removeClass('hide-header')
    }
  }
}

var actualTopExt = 0
var scrollBottomExt
var upTotalExt = 0

function fn_secondary_navigation_auto_hide(element, windowScrollTop) {
  if (actualTopExt == 0 || actualTopExt == undefined) {
    actualTopExt = windowScrollTop
  } else {
    if (actualTopExt < windowScrollTop) {
      //Dole
      upTotalExt = 0
      element.addClass('hide-navigation')

      $body.addClass('hide-header')
      $body.addClass('hide-header-bottom')

      actualTopExt = windowScrollTop
      scrollBottomExt = true
    } else {
      //Hore
      upTotalExt += actualTopExt - windowScrollTop
      $body.removeClass('hide-header-bottom')

      actualTopExt = windowScrollTop
      scrollBottomExt = false
    }
  }
}

var actualTop = 0
var upTotal = 0
var scrollBottom = 0

function fn_secondary_header_auto_hide(windowScrollTop) {
  if (actualTop == 0 || actualTop == undefined) {
    actualTop = windowScrollTop
  } else {
    if (actualTop < windowScrollTop) {
      //Dole
      upTotal = 0
      navigation.addClass('navigation-scroll-bottom')

      if ($('#detail-menu-bar').length) {
        detailBar.addClass('detail-scroll-bottom')
        detailBar.addClass('detail-tab-block-scroll-prepare')
      }
      actualTop = windowScrollTop
    } else {
      //Hore
      upTotal += actualTop - windowScrollTop
      if (upTotal >= 300) {
        if ($('#detail-menu-bar').length) {
          detailBar.removeClass('hide-navigation')
          detailBar.removeClass('detail-tab-block-scroll-prepare')
        }
        navigation.removeClass('navigation-scroll-bottom')
      } else {
        if ($('#detail-menu-bar').length) {
          detailBar.removeClass('detail-scroll-bottom')
        }
      }

      actualTop = windowScrollTop
    }
  }
}

// element to detect scroll direction of
var el = $('.js-horizontal-scroll'),
  isScrolledOnce = false,
  lastY = el.scrollTop(),
  lastX = el.scrollLeft()

el.find('.js-horizontal-scroll-overflow').on('scroll', function () {
  if (!isScrolledOnce) {
    var currY = el.find('.js-horizontal-scroll-overflow').scrollTop(),
      currX = el.find('.js-horizontal-scroll-overflow').scrollLeft(),
      x = currX > lastX ? 'right' : currX === lastX ? 'none' : 'left'

    if (x == 'right') {
      console.log($(this))
      $(this).closest('.scroll-icon').addClass('is-disabled')
      isScrolledOnce = true
    }

    lastX = currX
  }
})
