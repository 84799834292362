//====================================================
//  Initialization scroll
//====================================================
function componentProductList() {
  if ($('.js-fixed-item').length && $(window).width() < bpSmall) {
    checkScrollProductsList()
  }
}

if ($('.js-fixed-item').length) {
  var scrollingProductList = false
  var headerHeight = $('.js-navigation')
  var contentProductList = $('.js-fixed-item')
}

function checkScrollProductsList() {
  if (!scrollingProductList) {
    scrollingProductList = true
    !window.requestAnimationFrame
      ? setTimeout(updateProductsList, 300)
      : window.requestAnimationFrame(updateProductsList)
  }
}

function updateProductsList() {
  windowScrollTop = $(window).scrollTop()

  contentProductList.each(function () {
    var sectionItem = $(this),
      height = headerHeight.height()

    if (sectionItem.hasClass('is-active')) {
      if (
        sectionItem.offset().top - height < windowScrollTop &&
        sectionItem.offset().top + sectionItem.innerHeight() - height - 60 >
          windowScrollTop
      ) {
        sectionItem.addClass('is-fixed')
      } else {
        sectionItem.removeClass('is-fixed')
      }
    }

    // if (section.position().top - height - 150 < windowScrollTop) {
    //   // section.find('.headline-line').addClass(classIsActive)
    // }
  })
  scrollingProductList = false
}
