//====================================================
//  Function: Scroll to
//====================================================
function partScrollTo() {
  if ($('.js-scroll-trigger').length) {
    $('.js-scroll-trigger').bind('click', function () {
      var inst = $(this)
      var target = $(this).attr('data-scroll')
      if (windowWidth > bpMedium) {
        var navigationHeight = 90
      } else {
        var navigationHeight = 60
      }
      if (target.length) {
        if ($('#' + target + '').length == 0) {
          return false
        }
        if (
          $('.js-navigation').hasClass('is-collapse') &&
          $(window).width() < bpMedium
        ) {
          $('.js-navigation').removeClass('is-collapse')
        }
        $('html, body')
          .stop()
          .animate({
            scrollTop: $('#' + target + '').offset().top - navigationHeight,
          })
        return false
      }
    })
  }
}
