//====================================================
//  Function: Index Scroll
//====================================================
var counterScroll = 0
function componentIndexScroll () {
	if ($('.js-slider-about').length && $(window).width() > 992) {
		var counterScroll = 0
		var throttle = 400
		var time = -1

		$('html, body')
			.stop()
			.animate(
				{
					scrollTop: $('#section-plaster').offset().top - 105,
				},
				1000,
				function () {
					$body.addClass('page-stuck')
				},
			)

		// setTimeout(function () {
		//   if ($(window).scrollTop() > 10) {
		//     $('body').addClass('page-scrolling')
		//     aboutSliderInst.autoplay.start()
		//     $(document).off('wheel')
		//   }
		// }, 1000)

		$(document).on('wheel', function (e) {
			var now = Date.now()

			if (time !== -1 && now - time < throttle) {
				return
			}
			time = now

			var delta = e.originalEvent.deltaY
			if (delta > 0 && $(window).width() > 992) {
				if (counterScroll < 4) {
					if (counterScroll == 0) {
						aboutSliderInst.slideTo(1)
					}
					if (counterScroll == 1) {
						aboutSliderInst.slideTo(2)
					}
					if (counterScroll == 2) {
						aboutSliderInst.slideTo(3)
					}
					if (counterScroll == 3) {
						aboutSliderInst.slideTo(4)
					}
					counterScroll++
				} else {
					$('body').addClass('page-scrolling')
					$('html, body').animate({
						scrollTop: $('#section-our-services').offset().top - 100,
					}),
						4500
					aboutSliderInst.slideTo(1)
					// aboutSliderInst.autoplay.start()
					$(document).off('wheel')
				}
			} else {
				if (counterScroll > 0) {
					if (counterScroll == 1) {
						aboutSliderInst.slideTo(1)
					}
					if (counterScroll == 2) {
						aboutSliderInst.slideTo(2)
					}
					if (counterScroll == 3) {
						aboutSliderInst.slideTo(3)
					}
					if (counterScroll == 4) {
						aboutSliderInst.slideTo(4)
					}
					counterScroll--
				}
			}
		})
		aboutSlider()
	}
}
